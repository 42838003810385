<template>
    <div class="list-view">
        <page-title>
            <!-- <el-button @click="addRow" type="primary" icon="el-icon-plus" :loading="downloading" class="filter-item">
                新增
            </el-button> -->
            <!-- <el-button @click="download" icon="el-icon-upload2" :loading="downloading" class="filter-item">
                导出
            </el-button> -->
        </page-title>
        <div class="filters-container">
            <created-at-picker v-model="createdAt" @input="initData" name="注册"></created-at-picker>
            <el-input placeholder="搜索..." v-model="search" clearable class="filter-item search">
                <el-button @click="initData" slot="append" icon="el-icon-search"> </el-button>
            </el-input>
        </div>
        <el-table
            :data="tableData"
            row-key="id"
            ref="table"
            height="tableHeight"
            header-row-class-name="table-header-row"
            header-cell-class-name="table-header-cell"
            row-class-name="table-row"
            cell-class-name="table-cell"
        >
            <el-table-column v-if="multipleMode" align="center" type="selection" width="50"> </el-table-column>
            <el-table-column prop="id" label="ID" width="100">
                <template v-slot="{ row }">
                    <span @click="clickId(row)">{{ row.id }}</span>
                </template>
            </el-table-column>
<!--            <el-table-column prop="username" label="用户名"> </el-table-column>-->
            <el-table-column prop="nickname" label="昵称"> </el-table-column>
            <el-table-column prop="identityAuthName" label="真实姓名"> </el-table-column>
            <el-table-column label="头像">
                <template slot-scope="{ row }">
                    <el-image
                        style="width: 30px; height: 30px"
                        :src="row.avatar"
                        fit="cover"
                        :preview-src-list="[row.avatar]"
                    ></el-image>
                </template>
            </el-table-column>
            <el-table-column label="手机" prop="phone" min-width="100"></el-table-column>
            <el-table-column label="注册时间" prop="createdAt" min-width="150"></el-table-column>
            <el-table-column label="邀请人数" prop="inviteNum" min-width="80"></el-table-column>
            <el-table-column label="空投数量" prop="inviteAirDrop" min-width="80"></el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="400">
                <template slot-scope="{ row }">
                    <el-button @click="downloadUserInvitationInfo(row)" type="text" size="mini">导出</el-button>
                    <el-button @click="getInviteInfo(row)" type="text" size="mini">邀请列表</el-button>
                    <el-button @click="editRow(row)" type="text" size="mini">编辑</el-button>
                    <el-button v-if="!row.flag" @click="blockUser(row)" type="text" size="mini">封禁</el-button>
                    <el-button v-else @click="blockUser(row)" type="text" size="mini">取消封禁</el-button>
                    <el-button @click="removeCard(row)" size="mini" type="text" :disabled="row.bindCardCount == 0">
                        解绑卡
                    </el-button>
                    <el-button @click="queryBindStatus(row)" size="mini" type="text" :disabled="row.bindCardCount == 0">
                        杉德绑定状态
                    </el-button>
                    <el-button
                        @click="removeAuth(row)"
                        size="mini"
                        type="text"
                        :disabled="row.authStatus !== 'SUCCESS'"
                    >
                        取消认证
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination-wrapper">
            <el-pagination
                background
                @size-change="onSizeChange"
                @current-change="onCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalElements"
            >
            </el-pagination>
        </div>

        <el-dialog :visible.sync="showDialog" title="邀请列表" width="800px" top="10vh">
            <el-table :data="list" v-loading="dialogLoading" height="60vh">
                <el-table-column prop="id" label="ID" width="80"></el-table-column>
                <el-table-column prop="nickname" label="昵称"></el-table-column>
                <el-table-column prop="phone" label="手机"></el-table-column>
                <el-table-column prop="createdAt" label="注册时间"></el-table-column>
                <el-table-column prop="authStatus" label="实名审核状态" :formatter="statusFormatter"></el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableTable from '@/mixins/pageableTable';
import ClipboardJS from 'clipboard';
const clickData = {};
export default {
    mixins: [pageableTable],
    data() {
        return {
            multipleMode: false,
            search: '',
            url: '/user/all',
            downloading: false,
            createdAt: '',
            showDialog: false,
            dialogLoading: false,
            list: [],
            statusOptions: [
                { label: '未认证', value: 'NOT_AUTH' },
                { label: '认证中', value: 'PENDING' },
                { label: '已认证', value: 'SUCCESS' },
                { label: '失败', value: 'FAIL' }
            ]
        };
    },
    computed: {
        ...mapState([]),
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        }
    },
    methods: {
        blockUser(user) {
            this.$confirm('确认操作？', '警告', { type: 'error' }).then(()=>{
                let formData = JSON.parse(JSON.stringify(user))
                formData.flag = !formData.flag
                this.$http
                    .post('/user/save', formData, { body: 'json' })
                    .then(res => {
                        this.saving = false;
                        this.$message.success('成功');
                        this.getData();
                    })
                    .catch(e => {
                        this.$message.error(e.error);
                    });
            })

        },
        statusFormatter(row, column, cellValue, index) {
            let selectedOption = this.statusOptions.find(i => i.value === cellValue);
            if (selectedOption) {
                return selectedOption.label;
            }
            return '';
        },
        beforeGetData() {
            return { search: this.search, query: { createdAt: this.createdAt } };
        },
        afterGetData(res) {
            // let i = this.tableData.findIndex(i => i.username === 'root');
            // if (i > -1) {
            //     this.tableData.splice(i, 1);
            // }
        },
        toggleMultipleMode(multipleMode) {
            this.multipleMode = multipleMode;
            if (!multipleMode) {
                this.$refs.table.clearSelection();
            }
        },
        addRow() {
            this.$router.push({
                path: '/userEdit',
                query: {
                    ...this.$route.query
                }
            });
        },
        editRow(row) {
            this.$router.push({
                path: '/userEdit',
                query: {
                    id: row.id
                }
            });
        },
        download() {
            this.downloading = true;
            this.$axios
                .get('/user/excel', { responseType: 'blob' })
                .then(res => {
                    console.log(res);
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        operation1() {
            this.$notify({
                title: '提示',
                message: this.selection
            });
        },
        operation2() {
            this.$message('操作2');
        },
        clickId(row) {
            if (row.id !== clickData.id) {
                clickData.id = row.id;
                clickData.c = 0;
            }
            clickData.c = (clickData.c || 0) + 1;
            if (clickData.i) {
                clearInterval(clickData.i);
            }
            clickData.i = setTimeout(_ => {
                clickData.c = 0;
            }, 200);
            if (clickData.c === 5) {
                this.$http
                    .get(`/user/getToken/${row.id}`)
                    .then(res => {
                        let el = document.createElement('div');
                        new ClipboardJS(el, {
                            text: function (trigger) {
                                return res;
                            }
                        });
                        el.click();
                        this.$message.success('已复制Token');
                        clickData.c = 0;
                    })
                    .catch(e => {
                        this.$message.error(e.error);
                    });
            }
        },
        removeCard(row) {
            this.$msgbox({
                title: '确认解绑吗？',
                message: '该操作无法撤回，请谨慎操作',
                showCancelButton: true,
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        instance.showCancelButton = false;
                        instance.closeOnClickModal = false;
                        instance.showClose = false;
                        this.$http
                            .post('/user/removeBankCardAdmin', { userId: row.id })
                            .then(res => {
                                done();
                                this.getData();
                                this.$message.success('解绑成功');
                                instance.confirmButtonLoading = false;
                                instance.showCancelButton = true;
                                instance.closeOnClickModal = true;
                                instance.showClose = true;
                            })
                            .catch(e => {
                                done();
                                this.getData();
                                this.$message.error(e.error || '解绑失败');
                                instance.confirmButtonLoading = false;
                                instance.showCancelButton = true;
                                instance.closeOnClickModal = true;
                                instance.showClose = true;
                            });
                    } else {
                        done();
                    }
                }
            }).then(_ => {});
        },
        queryBindStatus(row) {
            this.$http
                .post('/user/queryBindStatus', { userId: row.id })
                .then(res => {
                    if (res) {
                        this.$message.success('已绑定');
                    }else{
                        this.$message.info('未绑定')
                    }
                })
                .catch(e => {
                    this.$message.error(e.error || '查询失败');
                });
        },
        removeAuth(row) {
            this.$msgbox({
                title: '确认取消认证吗？',
                message: '该操作无法撤回，请谨慎操作',
                showCancelButton: true,
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        instance.showCancelButton = false;
                        instance.closeOnClickModal = false;
                        instance.showClose = false;
                        this.$http
                            .post('/user/removeAuthAdmin', { userId: row.id })
                            .then(res => {
                                done();
                                this.getData();
                                this.$message.success('取消认证成功');
                                instance.confirmButtonLoading = false;
                                instance.showCancelButton = true;
                                instance.closeOnClickModal = true;
                                instance.showClose = true;
                            })
                            .catch(e => {
                                done();
                                this.getData();
                                this.$message.error(e.error || '取消认证失败');
                                instance.confirmButtonLoading = false;
                                instance.showCancelButton = true;
                                instance.closeOnClickModal = true;
                                instance.showClose = true;
                            });
                    } else {
                        done();
                    }
                }
            }).then(_ => {});
        },
        getInviteInfo(row) {
            this.list = [];
            this.showDialog = true;
            this.dialogLoading = true;
            this.$http
                .post('/user/all', { size: 10000, sort: 'id,desc', query: { invitor: row.id } }, { body: 'json' })
                .then(res => {
                    this.list = res.content;
                    this.dialogLoading = false;
                });
        },
        downloadUserInvitationInfo(row){
            this.downloading = true;
            this.$axios
                .request({
                    method:"post",
                    url: '/user/exportUserInvitationInfo',
                    responseType: 'blob',
                    data: {query: { id : row.id}}
                })
                .then(res => {
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', decodeURIComponent(res.headers['content-disposition'].split('filename=')[1]));
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
    }
};
</script>
<style lang="less" scoped></style>
